import * as React from "react";
import MainLayout from "../layouts/MainLayout";
import TeamFull from "../sections/TeamFull/TeamFull";

const TeamPage = () => {
  return (
    <MainLayout pageTitle={"Team | Omnia"} footerSection={"team"}>
      <TeamFull />
    </MainLayout>
  );
};

export default TeamPage;
